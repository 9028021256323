import React from 'react';

const RatingLine = ({
	text,
  color
}) => (
  <div
    style={{ backgroundColor: color }}
    className="product__rating-line">
    <span>{ text }</span>
  </div>
);

export default RatingLine;
