import React, { Fragment } from 'react';
import Button from './Button';
import RatingMapper from './RatingMapper';
import Slider from './Slider';
import defaultChoices from './defaultChoices';

const ConfiguratorContainer = ({
  id,
  visible,
  color,
  onClick,
  slider,
  sliderValues = {},
  handleSliderChange,
  zIndex,
}) => (
  <Fragment>
    <Button
      name={ id }
      color={ color }
      zIndex={ zIndex }
      onClick={ onClick } />
      <div className={ visible ? 'accordion' : 'accordion is-hidden' } >
        <div style={{padding: '40px 0'}} >
          { 
            slider.map(({ id, text, val_text }, i) => {
              const ratings = val_text || defaultChoices
              return (
                <div
                  key={ id }
                  className="configurator__slider">
                  <div className="configurator__title">
                    {text}<br/>
                  </div>
                  <RatingMapper
                    isActive={ sliderValues[id] }
                    ratings={ratings} />
                  <Slider
                    id={ id }
                    value={ sliderValues[id] }
                    onChange={ e => handleSliderChange( e, id ) } />
                </div>
              )})
          }
        </div>
      </div>
  </Fragment>
)


export default ConfiguratorContainer;