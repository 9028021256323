import React, { PureComponent } from 'react';
import axios from 'axios';
import Search from './SearchConstructor';

class SearchProvider extends PureComponent {

  constructor(props) {
    super();
    this.state = {
      searchInput: 'Pizza',
      isFetching: false,
      searchResult: {},
      hasError: false,
    }
  }

  componentDidMount() {
    this.fetchSearch()
  }

  onInput = e => {
    this.setState({ searchInput: e.target.value })
  }

  onKeyPress = e => {
    if(e.keyCode === 13){
      this.fetchSearch()
    }
  }

  fetchSearch() {
    const url = 'https://api.baggid.com/products/?q=' + this.state.searchInput;
    const _this = this;
    axios.get(url)
    .then(function (response) {
      // console.log(response);
      _this.searchSuccess(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  fetchMore = () => {
    // console.log('Fetch more')
    const url = this.state.searchResult.next_page
    const _this = this;
    axios.get(url)
    .then(function (response) {
      console.log(response);
      _this.addToResult(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  searchSuccess = data => {
    // Hier muss später die Möglichkeit bestehen zur bisherigen Suche zu pushen
    const searchResult = new Search(data)
    this.setState({ searchResult })
  }

  searchError = data => {
    console.error('Suche schief gegangen')
  }

  addToResult = data => {
    // console.log(data)
    // console.log(this.state.searchResult)
    const searchResult = new Search(data, this.state.searchResult.list)
    // const completeSearch = {
    //   ...this.state.searchResult,
    //   ...searchResult.list
    // } 
    console.log(searchResult)
    // console.log(completeSearch)
    this.setState({ searchResult })
  }

  render() {
    const { children } = this.props;
    const { searchInput, searchResult } = this.state;
    const input = {
      val: searchInput,
      onChange: this.onInput,
      onKeyPress: this.onKeyPress,
    }
    return(
      <div>
        Search
          { React.Children.map(children, child => {
          return React.cloneElement(child, {
            input,
            searchResult,
            fetchMore: this.fetchMore
          }, null);
        })}
      </div>
    )
  }
}

export default SearchProvider