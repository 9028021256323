import React from 'react';
import { getType } from '../../assets/functions/isArray';

const ProgressButton = ( props ) => {
  const { content, onClick } = props;
  const arrow = <i className="fas fa-arrow-right" />
  switch( getType(content) ) {
    case 'undefined':
      return <SingleButton onClick={onClick} content={arrow} />
    case 'array':
      return content.map(( single, i ) => (
        <SingleButton
          key={ i }
          i={ i }
          content={ single }
          onClick={ onClick } />
      ))
    default:
      return <SingleButton {...props} />
  }
}

const SingleButton = ({
  onClick,
  content,
}) => {
  return (
    <button
      style={ content.style }
      className="btn noSelect btn-outline"
      onClick={ () => onClick( content ) } >
      { content.text || content }
    </button>
  )
}

export default ProgressButton;
