import React from 'react';

const Button = ({
  color,
  zIndex,
  name,
  onClick,
}) => (
  <div
    style={{ backgroundColor: color, zIndex }}
    className="btn configurator-button flex-center"
    onClick={ onClick } >
    { name }
  </div>
)

export default Button
