import React, { Fragment } from 'react';
import NewsletterView from './NewsletterView';
import SuccessPage from './SuccessPage';
import ErrorPage from './ErrorPage';

const NewsletterHandler = ( props ) => {

  switch( props.submitionStatus ) {
    case 'success':
      return <SuccessPage />

    case 'error':
      return <ErrorPage onSubmit={ props.onSubmit } />
      
    default :
      return (
        <Fragment>
          <NewsletterView {...props} />
        </Fragment>
    )
  }
}

export default NewsletterHandler;