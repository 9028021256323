import * as _ from 'lodash';

export default function CategoryTreeCreator({ name, _links, _embedded }) {
  const parent_list = getParents( _embedded.parent )
  const product_list = _embedded['bgd:product'] || []
  const children_list = getChildrenList(_embedded)
  this.name = name
  this.product_list = product_list
  this.children_list = children_list
  this.parent_list = parent_list
  this.hasProducts = product_list.length > 0;
  this.hasParents = parent_list.length > 0;
  this.hasChildren = children_list.length > 0;
  this.self_link = _links.self.href;
}

function getChildrenList(_embedded) {
  const oldList = _embedded['bgd:category'] || []
  let newList = []
  oldList.forEach(({ name, _links }) => {
    newList.push({
      name,
      link: _links.self.href
    })
  })
  return newList
}

function getParents( parents ) {
  let parentList = [];
  let current = parents
  let safety = 0
  while ( current !== undefined && safety < 10 ) {
    safety ++
    parentList.unshift({
      name: current.name,
      link: current._links.self.href
    })
    current = _.get(current, '_embedded.parent')
  }
  return parentList
}