import React from 'react'
import Configurator from './configurator/Configurator'
import Newsletter from './newsletter/Newsletter'
import Team from './Team';
import monsterError from '../../assets/img/monsters/monster-error.gif'
import SharePage from './SharePage';
import Green from './elements/Green';

// const color = '#31D4AB';
const color = { color: '#31D4AB' }
const urlNewsletter = 'https://hooks.zapier.com/hooks/catch/561863/ptpakt/'

// As soon as this has a URL
const urlAndroid = 'https://hooks.zapier.com/hooks/catch/561863/phyzte/';

const newsletterText = (
  <div>
    <p>
      Trag dich in unseren Newsletter ein und <Green>hilf uns dabei Relevanz zu gewinnen.</Green>
    </p>
    Mit 5.000 Einträgen können wir die ersten Hersteller angehen und <Green>von da aus geht es weiter.</Green>
  </div>
)

const newsletterAndroidText = (
  <div>
    <p>
    Wenn du kein iPhone hast, aber gern die App hättest, dann trag dich in unseren <Green>Newsletter für Android ein.</Green>
    </p>
    <p>Wir schicken dir dir eine Nachricht, <Green>sobald die App draußen ist.</Green>
    </p>
  </div>
)


const content = [
  
  { 
    text: (
      <div>
        Willst du du dich <Green>gesund ernähren?</Green>
      </div>
      ),
    button: [
      {
        text: 'Nö',
        style: { flex: '1 2 100%' }
      },
      { 
        text: 'Klar',
        link_to: 'is_interrested'
      }
    ]
  },
  { 
    id: 'not_interrested',
    text: (
      <div>
        <img src={monsterError} alt='Monster Bettergood' width='200' />
        <br/>
        <p style={{ marginTop: '30px' }} >
          Dann können wir dir leider nicht helfen
        </p>
      </div>
    ),
    button: 'Na gut, doch',
  },
  { 
    id: 'is_interrested',
    text: 'Wir auch',
    button: 'Ok?',
  },
  { 
    text: (
      <div>
        Aber weißt du auch <Green>wie du das machst?</Green>
      </div>
    ),
    button: {
      text: 'Nicht immer',
      trigger: 'App Store'
    },
  },
  { 
    text: (
      <div>
        <p style={ color } >Wir auch nicht</p>
        <p>Deshalb haben wir <Green>eine App</Green> entwickelt, die dir dabei helfen soll. Für das iPhone findest du sie schon im App Store, Android muss noch etwas warten.</p>
      </div>
    ),
    button: 'Sag mir mehr',
  },

  {
    text: (
      <div>
        <p>Unsere App soll zukünftig auf einen Blick die älteste Frage der Menschheit beantworten</p>
        <p style={ color }>Kann ich das essen?</p>
        <p>Was das angeht haben wir eine gute und eine schlechte Nachricht.</p>
      </div>
      ),
    button: 'Zuerst die Gute',
  },
  { 
    text: (
      <div>
        <p>
          Unsere App bietet dir einen <Green>persönlichen Ernährungsberater</Green>völlig kostenlos und jederzeit verfügbar.
        </p>
        <p>Ein Berater, der immer die aktuellsten Informationen vergleicht und nach deinen Wünschen bewertet.</p>
        <p style={ color }>
          Und das in Echtzeit
        </p>
      </div>
    ),
    button: 'Ok, und die Schlechte?',
    
  },
  {
    text: (
      <div>
        Es ist erschreckend, wie <Green>wenig Informationen zu Produkten</Green>existieren, oder zumindest frei verfügbar sind.
      </div>
    ),
  },
  {
    text: (
      <div>
        <p>
        Das wollen wir ändern.
        </p>
        <p style={ color } >Und du kannst uns dabei helfen</p>
      </div>
    ),
    button: 'Ich?',
  },
  {
    text: (
      <div>
        Hilf uns den Herstellern zu zeigen, <Green>dass es einen Bedarf nach Transparenz gibt.</Green>Dass wir als Verbraucher wissen wollen, was wir essen.
      </div>
    ),
    button: 'Wie?',
  },
  {
    id: 'newsletter',
    text: (
      <div>
        <Newsletter text={ newsletterText } url={ urlNewsletter } />
        Und dann haben wir noch eine Frage?
      </div>
    ),
    button: 'Ok?',
  },
  {
    text: (
      <div>
      <p>
      Was ist dir eigentlich wichtig?
      Wir haben einen Dienst entwickelt, der jedem helfen soll sich so zu ernähren, wie er oder sie es will. <Green>Aber wie willst du dich eigentlich ernähren?</Green>
      </p>

      <p>Sag uns was dich interessiert, und hilf uns unseren Service noch <Green>besser auf deine Wünsche anzupassen.</Green></p>
      </div>
    ),
    // button: ['Kein Bock', 'Klar'],
    button: [
      { 
        text:'Kein Bock',
        link_to: 'after_configurator',
        style: { flex: '1 2 100%' }
      },
      'Klar'
    ],
  },
  {
    id: 'configurator',
    child: <Configurator />,
  },
  {
    text: `Danke für deine Unterstützung`,
    // button: 'Und jetzt?',
  },
  {
    id:'after_configurator',
    text: (
      <div>
        <Newsletter text={newsletterAndroidText} url={ urlAndroid } />
      </div>
    ),
    button: 'Wer seid ihr eigentlich?',
  },
  {
    id:'team',
    text: <Team />,
    button: 'Und wie verdient ihr Geld?',
  },
  {
    text: (
      <div>
      <p style={ color } >Aktuell gar nicht.</p>
        <p>
        Mit unserer Firma baggid verkaufen wir Software Lösungen und Apps an Kunden.
        </p>
        <p>
        So hilft unser System z.B. der <Green>Friedrich Schiller Universität Jena</Green>die Ergebnisse ihrer Ernährungsstudien einer breiten Öffentlichkeit zur Verfügung zu stellen. Und das
        <Green> in leicht verständlicher Form.</Green>
        </p>
    
    
        Bettergood entwickeln wir eigenständig, <Green>weil wir selbst immer eine App wollten,</Green> die uns beim Einkaufen hilft und die richtigen Rezepte vorschlägt.
      </div>
    ),
    button: 'Und das bleibt kostenlos?',
  },
  {
    text: `Ja.
    Vielleicht bauen wir irgendwann mal irgendwo Werbung ein, oder machen ein paar kleine Premium features, bisher ist sowas aber nicht geplant.

    Wir wollen mit unserem Dienst dazu beitragen, dass jeder Mensch die Chance bekommt sich gesund zu ernähren.`,
    button: 'Bin dabei',
  },
  {
    text: (
      <div>
        <p><Green>Danke!</Green></p>
        <p>
          Wir arbeiten kontinuierlich daran dir neue Dienste auf unserer Seite zur Verfügung zu stellen. <Green> Es lohnt sich gelegentlich vorbei zu schauen.</Green>
        </p>
        <Newsletter
          url={ urlNewsletter } />
      </div>
    ),
    button: 'Spread the word',
  },
  {
    id: 'share_page',
    text: <SharePage size={50} />
  },
]

export default content;