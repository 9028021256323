import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';
import axios from 'axios';
import NewsletterHandler from './NewsletterHandler';

function sendDataToZapier( url, data, onSuccess, onError ) {
  axios({
    method: 'post',
    url,
    crossDomain: true,
    data: JSON.stringify( data )
  })
  .then(function (response) {
    // console.log(response);
    onSuccess()
  })
  .catch(function (error) {
    console.log(error);
    onError()
  });
}

function sendDataToBaggid( data, onSuccess, onError ) {
  console.warn('Muss noch an Baggid gepostet werden')
}

class Newsletter extends PureComponent {

  state = {
    mail: '',
    isSubmitting: false,
    submitionStatus: '',
  }

  onChange = e => {
    this.setState({ mail: e.target.value })
  }

  onSubmit = e => {
    e.preventDefault();
    const { url } = this.props;
    const data = { mail: this.state.mail }
    this.setState({ isSubmitting: true })
    sendDataToZapier( url, data, this.onSuccess, this.onError )
    sendDataToBaggid( data, this.onSuccess, this.onError )
    ReactGA.event({
      category: 'Newsletter',
      action: 'subscribe',
      label: 'global support'
    });
  }

  onSuccess = () => {
    this.setState({ isSubmitting: false, submitionStatus: 'success' })
  }

  onError = () => {
    this.setState({ isSubmitting: false, submitionStatus: 'error' })
  }

  render() {
    const { isSubmitting, mail, submitionStatus } = this.state;
    const { text, text_fix } = this.props;
    const iconClass = isSubmitting ? 'fas fa-spinner fa-spin' : "fa fa-paper-plane"
    return (
      <div className="newsletter">
      <NewsletterHandler
        text={ text }
        input={ mail }
        onChange={ this.onChange }
        onSubmit={ this.onSubmit }
        iconClass={ iconClass }
        submitionStatus={ submitionStatus } />
        <br/><br/>
        { text_fix }
      </div>
    )
  }
}

export default Newsletter;