import React from 'react';

const CategorySingle = ({
  name,
  onClick,
  className,
}) => (
  <div
    onClick={ onClick }
    className={ className } >
    { name }
  </div>
)

export default CategorySingle
