import React from 'react';
import SearchProvider from './SearchProvider';
import SearchInput from './SearchInput';
import ProductList from './ProductList';

const SearchContainer = () => (
	<SearchProvider>
    <SearchInput />
    <ProductList />
  </SearchProvider>
)

export default SearchContainer;