import React, { Component } from 'react';
import { Provider } from 'react-redux';
import GlobalRouter from './GlobalRouter';
import store from './store/store';
import './assets/css/style.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-98822702-1');
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <GlobalRouter />
      </Provider>
    );
  }
}

export default App;
