import React from 'react';
import Slider from 'react-rangeslider'

const SliderView = ({
  id,
  value,
  onChange
}) => (
  <Slider
    min={0}
    max={3}
    value={ value }
    step={1}
    onChange={ e => onChange(e, id) }
  />
);

export default SliderView;
