import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
// import OpeningScreen from './routes/opening-screen/OpeningScreen'
import OpeningContainer from './routes/opening-screen/OpeningContainer';
import SearchContainer from './routes/search/SearchContainer';
import CategoryContainer from './routes/categories/CategoryContainer';


const GlobalRouter = () => (
  <Router>
    <div className="full-size">
      <Route exact path="/" component={ OpeningContainer } />
      <Route path="/search" component={ SearchContainer } />
      <Route path="/categories" component={ CategoryContainer } />
    </div>
  </Router>
)

export default GlobalRouter