import React from 'react';
import cenk from '../../assets/img/team/cenk-bw.jpg';
import willem from '../../assets/img/team/willem-bw.jpg';
import johannes from '../../assets/img/team/johannes-bw.jpg';
import sinan from '../../assets/img/team/sinan-bw.jpg';

const Img = ({
  src,
  name,
  title
}) => (
  <div className="team">
    <img
      className="team-img"
      src={src}
      alt={name} />
    <p className="team-name">{name}</p>
    <p className="team-title">{title}</p>
  </div>
)

const Team = () => (
	<div style={{flexWrap: 'wrap'}} className="flex-center team-container">
    <Img src={sinan} name='Sinan Theuvsen' title='CEO' />
    <Img src={willem} name='Willem Romijn' title='CTO' />
    <Img src={johannes} name='Johannes Lang' title='ENGINEER' />
    <Img src={cenk} name='Cenk Toprak' title='BUSINESS DEVELOPMENT' />
  </div>
)

export default Team;