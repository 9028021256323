export default function isArray(data) {
	const isArr = Object.prototype.toString.call(data) === '[object Array]';
	return isArr
}

export function getType(data) {
  if ( isArray(data) ) {
    return 'array'
  }
  return typeof data
}