import React, { PureComponent } from 'react';
import RatingLine from './RatingLine';

class ProductListSingle extends PureComponent {
  render() {
    const { rating, name, img } = this.props.product;
    return (
      <div className="product">
        <div className="product__box">
          { name }
          <div className="product__img">
            <img src={ img } alt='' />
          </div>
        <RatingLine
          color={ rating.color }
          text={ rating.text } />
        </div>
      </div>
    )
  }
}

export default ProductListSingle;