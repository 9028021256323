const initialState = {
  
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    
    case 'STORE_CATEGORIES':
      return action.categories;
    
    default :
      return state;
  }
}