import React, { PureComponent } from 'react';
import axios from 'axios';
import ConfiguratorContainer from './ConfiguratorContainer';
import Button from './Button';
import content from './content';
import defaultChoices from './defaultChoices';

function getSubmitData(slides) {
  var clone = content.slice(0);
  clone.forEach(( choice ) => {
    for ( let i in choice.slider ) {
      const { id, val_text } = choice.slider[i]
      const val = slides[id] || 0
      const choiceTexts = val_text || defaultChoices
      choice.slider[i].val = val
      choice.slider[i].choice_text = choiceTexts[val]
      choice.slider[i].was_changed = slides[id] !== undefined
    }
  })
  return convertData(clone)
}

function convertData(data) {
  const string = JSON.stringify( data )
  const toSend = 'form=' + string
  return toSend
}


class Configurator extends PureComponent {

  state = {
    slides: {},
    visible : 0,
    content
  }

  setVisible(val) {
    if ( val === this.state.visible ) {
      this.setState({ visible: 0 })
    } else {
      this.setState({ visible: val })
    }
  }

  handleChange = (value, id) => {
    this.setState({ 
      slides: {
        ...this.state.slides,
        [id]: value 
      }
    })
  };

  onSubmit = () => {
    const data = getSubmitData( this.state.slides )
    // console.log('Submit:', data)
    this.submitData(data)
    this.props.onClick()
  }

  submitData( data ) {
    // const x = JSON.stringify({form: 'test'})
    console.log(data)
    axios({
      method: 'post',
      url: 'https://locotigmbh.com/_projects/sinan/newsletter.php',
      data: data
    })
    .then(function (response) {
      console.log(response)
    })
    .catch(function (error) {
      console.log(error)
    });
  }

  render() {
    const { visible, content, slides } = this.state;

    return (
      <form name="configurator" method="POST" data-netlify="true">
        <div className="configurator__container">
          {
            content.map(({ id, color, slider }, index ) => {
              const i = index + 1
              return (
              <ConfiguratorContainer
                key={ i }
                id={ id }
                zIndex={ 100 - i }
                color={ color }
                visible={ visible === i }
                onClick={ () => this.setVisible( i ) }
                slider={ slider }
                sliderValues={ slides }
                handleSliderChange={ this.handleChange } />
            )})
          }
          <Button
            name='Done'
            color='#0f0a3c'
            type="submit"
            onClick={ this.onSubmit } />
        </div>
      </form>
    )
  }
}

export default Configurator;