import React from 'react';
import imgAppStore from '../../assets/img/Download_on_the_App_Store_Badge.png';

const AppStoreButton = ({ onClick = ()=>{}, isVisible }) => {
  if ( isVisible ) {
    return (
      <a
        onClick={ onClick }
        href="https://itunes.apple.com/app/bettergood/id1380165060"
        target='_blank'
        rel="noopener noreferrer"
        style={{ padding: '10px' }} >
        <img src={imgAppStore} alt='App Store' height='40' />
      </a>
    )
  }
  return null
}

export default AppStoreButton
