const healthRatingNames = [
  'besonders gesund',
  'gesund',
  'eher gesund',
  'eher ungesund',
  'ungesund',
  'besonders ungesund'
]

const colors = [
  '#31d4ab',
  '#5cdb7e',
  '#a1de64',
  '#fcaf51',
  '#fa744b',
  '#eb5261'
]

const classNames = [
  'plus-3',
  'plus-2',
  'plus-1',
  'minus-1',
  'minus-2',
  'minus-3',
]

export default function RatingCreator( ratings = {} ) {
  let result = {};
  for ( let i in ratings ) {
    result[i] = convertRating( i, ratings[i] )
  }
  // console.log(result)
  return result
}

function convertRating( key, rating ) {
  // const name = key.split('_')[0];
  const text = healthRating( rating, healthRatingNames, 'unbewertet' );
  const color = healthRating( rating, colors, 'lightgray' )
  const className = healthRating( rating, classNames, 'unrated' )
  const result = {
    text,
    color,
    className
  }
  // console.log(result)
  return result
}

function healthRating( rating, resultList = [], empty = null ) {
  if ( typeof rating !== 'number' ) {
    return empty
  }
  switch( true ) {
    case ( rating > 1 ):
      return resultList[0]
    case ( rating > .5 ):
      return resultList[1]
    case ( rating > 0 ):
      return resultList[2]
    case ( rating > -.5 ):
      return resultList[3]
    case ( rating > -1 ):
      return resultList[4]
    case ( rating <= -1 ):
      return resultList[5]
    default :
      return empty
  }
}

