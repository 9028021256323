import React, { PureComponent } from 'react';

class SearchInput extends PureComponent {
  render() {
    const { val, onChange, onKeyPress } = this.props.input;
    return (
      <input
        onChange={ onChange }
        defaultValue={ val }
        val={ val }
        onKeyDown={ onKeyPress } />
    )
  }
}

export default SearchInput;