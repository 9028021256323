import React, { PureComponent } from 'react';
import ProductListSingle from './ProductListSingle';
import InfiniteScroll from 'react-infinite-scroller';

class ProductList extends PureComponent {

  loadFunc = () => {
    this.props.fetchMore()
  }

  render() {
    const { hasResult, list } = this.props.searchResult
    if ( hasResult ) {
      const productList = list.map(( product, i ) => (
        <ProductListSingle
          key={ i }
          product={ product } />
      ))
      // return (
      //   <div className="content">
      //     <div className="flex flex-wrap">
      //       { productList }
      //     </div>
      //   </div>
      // )
      return (
        <InfiniteScroll
            pageStart={0}
            loadMore={this.loadFunc}
            hasMore={true || false}
            loader={<div className="loader" key={0}>Loading ...</div>} >
          <div className="content">
            <div className="flex flex-wrap">
              { productList }
            </div>
          </div>
        </InfiniteScroll>
      )
    }
    return null;
  }
}

export default ProductList;