import React from 'react';
import CategorySingle from './CategorySingle';

const CategoryList = ({
  fetchCategory,
  categories
}) => {
  // const parents = categories.parent_list || []
  // const parentList = parents.slice(0).reverse()
  // console.log(parentList)
  return(
    <div>
      { categories.hasParents &&
        categories.parent_list.map(({ name, link }, i) => (
          <CategorySingle
            key={ i }
            className='category-slide parent'
            name={ name }
            onClick={ () => fetchCategory( link ) } />
        ))
      }
      <CategorySingle
        className='category-slide active'
        name={ categories.name } />
      { categories.hasChildren &&
        categories.children_list.map(({ name, link }, i) => (
          <CategorySingle
            key={ i }
            className='category-slide'
            name={ name }
            onClick={ () => fetchCategory( link ) } />
        ))
      }
    </div>
  )
}

export default CategoryList;
