import React from 'react';

const BackButton = ({
  onClick,
  isVisible
}) => (
	<div
    style={ isVisible ? {} : { display: 'none' } }
    onClick={ onClick }
    className="back-button">
    <i className="fas fa-arrow-left" />
  </div>
)

export default BackButton;
