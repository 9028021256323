import React from 'react';
import CategoryProvider from './CategoryProvider';
import CategoryList from './CategoryList';

const CategoryContainer = () => (
  <CategoryProvider>
    <CategoryList />
  </CategoryProvider>
)

export default CategoryContainer;