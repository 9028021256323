import { createStore, compose, applyMiddleware } from 'redux';
import rootReducer from './rootReducer';
import storeProvider from './storeProvider';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk),
);

const configureStore = () => {
	return createStore(
		rootReducer,
		enhancer
		// composeEnhancers()
	);
}

storeProvider.init(configureStore);

const store = storeProvider.getStore();

export default store;
