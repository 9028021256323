import React from 'react';
import ContentAnimator from './ContentAnimator';
import AppStoreButton from './AppStoreButton';
import BackButton from './BackButton';
import content from './content';

const smallerStyle = { height: 'calc(90% - 60px)' }

const OpeningView = ({
  showAppStore,
  onAppStoreClick,
  page,
  onProgress,
  onBack,
  showBackButton,
  direction
}) => {
  return (
    <div className="full-size-bg bg-opening">
        <div className="box-overlay flex-center flex-column">
          <AppStoreButton
            isVisible={ showAppStore }
            onClick={ onAppStoreClick } />
          <div style={ showAppStore
              ? smallerStyle
              : {}
            } 
            className="center-box rounded-corners">
            <BackButton
              onClick={ onBack }
              isVisible={ showBackButton } />
            { content.map((props, index) => (
              <ContentAnimator
                { ...props }
                key={ index }
                isIn= { index === page }
                onClick={ onProgress }
                direction={ direction } />
            ))}
          </div>
        </div>
      </div>
  )
}

export default OpeningView;
