import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  // TelegramShareButton,
  // WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  EmailIcon
} from 'react-share';
import Green from './elements/Green';

const SharePage = ({
  url = 'https://bettergood.de',
  size = 40,
}) => (
  <div>
    Wenn du noch jemanden kennst, dem wir helfen können, 
    <Green> sag es gerne weiter!</Green>
    <div className='flex-center gap-top-60'>
      <FacebookShareButton url={ url } >
        <FacebookIcon size={ size } />
      </FacebookShareButton>
      <TwitterShareButton url={ url } >
        <TwitterIcon size={ size } />
      </TwitterShareButton>
      <EmailShareButton url={ url } >
        <EmailIcon size={ size } />
      </EmailShareButton>
    </div>
  </div>
)

export default SharePage;
