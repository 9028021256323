import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions';
import axios from 'axios'
import CategoryTree from './CategoryTreeConstructor';

class CategoryProvider extends PureComponent {

  componentDidMount() {
    this.fetchCategory()
  }

  fetchCategory = (
    url = 'https://api.baggid.com/categories/1'
    // key = 1
  ) => {
    console.log(url)
    // const baseUrl = 'https://api.baggid.com/categories/'
    // const url = baseUrl + key
    const _this = this;
    axios.get(url)
    .then(function (response) {
      // console.log(response);
      _this.storeCategories(response.data)
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  storeCategories(data) {
    const categories = new CategoryTree(data)
    this.props.dispatch( actions.storeCategories(categories) )
  }

  render() {
    const { children, categories } = this.props;
    return(
      <div>
          { React.Children.map(children, child => {
          return React.cloneElement(child, {
            fetchCategory: this.fetchCategory,
            categories,
            // input,
            // searchResult,
            // fetchMore: this.fetchMore
          }, null);
        })}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  categories: state.categories
})

export default connect(mapStateToProps)(CategoryProvider);