import React from 'react';
import monsterError from '../../../assets/img/monsters/monster-error.gif'

const ErrorPage = ({ onSubmit }) => (
  <div className="flex-center flex-column">
    Da ist was schief gelaufen
      <img
        className="img-monster"
        src={monsterError}
        alt='bettergood error monster' />
    <button
      className="btn green"
      onClick={ onSubmit } >
      Nochmal versuchen
    </button>  
  </div>
)

export default ErrorPage;