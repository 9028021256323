import React, { Fragment } from 'react';

const NewsletterView = ({
  text,
  input,
  onChange,
  onSubmit,
  iconClass
}) => (
  <Fragment>
    { text }
    <br/><br/>
    <form className="flex-center newsletter__input">
      <input
        type="email"
        className=""
        placeholder="mail@you"
        autoComplete="email"
        value={ input }
        onChange={ onChange } />
      <button
        className="btn newsletter__submit green"
        type="submit"
        onClick={ onSubmit } >
        <i
        style={{ marginRight: '7px' }}
        className={ iconClass }
        aria-hidden="true" />
      </button>
    </form>
  </Fragment>
)

export default NewsletterView;