import React from 'react';

const style = { color: '#31D4AB' }
const Green = ({ children }) => {
  return (
  <span style={style} >
    { children }&nbsp;
  </span>
)}

export default Green;