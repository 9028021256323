const content = [
  {
    id: 'Gesundheit',
    slider: [
      {
        id: 'health__overall',
        text: 'Gesunde Ernährung ist mir wichtig'
      },
      {
        id: 'health__ingredients',
        text: 'Ich lese beim Kaufen die Zutaten',
        val_text: [ 'Nie', 'selten', 'die meisten', 'immer' ]
      },
      {
        id: 'health__nutrition',
        text: 'Ich achte auf meine Nährwerte'
      },
      {
        id: 'health__price',
        text: 'Ich bin bereit einen höheren Preis für gesunde Lebensmittel zu zahlen',
        val_text: [ 'Nein', 'bis 5%', 'bis 10%', 'bis 20%' ]
      },
    ]
  },
  {
    id: 'Umwelt',
    color: '#009f9d',
    slider: [
      {
        id: 'env__organic',
        text: 'Ich achte auf Bio Siegel'
      },
      {
        id: 'env__regional',
        text: 'Ich versuche regionale Produkte zu kaufen'
      },
      {
        id: 'env__understand',
        text: 'Verstehst du eigentlich was welches Siegel sagt',
        val_text: [ 'Nein', 'Ich hoffe', 'Größtenteils', 'Absolut' ]
      },
      {
        id: 'env__price',
        text: 'Für umweltfreundliche Produkte bin ich bereit mehr zu zahlen',
        val_text: [ 'Nein', 'bis 5%', 'bis 10%', 'bis 20%' ]
      },
    ]
  },

  {
    id: 'Soziales',
    color: '#07456f',
    slider: [
      {
        id: 'social__fair',
        text: 'Fairer Handel ist mir wichtig'
      },
      {
        id: 'social_animal',
        text: 'Artgerechte Tierhaltung ist mir wichtig'
      },
      {
        id: 'social__price',
        text: 'Für faire Produkte bin ich bereit mehr zu zahlen',
        val_text: [ 'Nein', 'bis 5%', 'bis 10%', 'bis 20%' ]
      },
    ]
  },
]

export default content;