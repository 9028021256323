import React from 'react';
import ProgressButton from './ProgressButton';

const ContentProvider = ({
  text,
  button,
  child,
  onClick,
}) => {
  if ( child ) {
    return React.cloneElement(child, {
      onClick: onClick
    }, null);
  }
  return (
    <div className='center-box__content-container' >
      <div className="center-box__content">
        { text }
      </div>
      <div className="center-box__btn-area">
        <ProgressButton
          onClick={ onClick }
          content={ button } />
      </div>
    </div>
  )
};

export default ContentProvider;
