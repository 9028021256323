import React from 'react';
import { CSSTransition } from 'react-transition-group';
import ContentProvider from './ContentProvider';

function getClass(direction) {
  if ( direction === 'back' ) {
    return 'message--back'
  }
  return 'message';
}

const ContentAnimator = ( props ) => (
  <CSSTransition
    in={props.isIn}
    timeout={800}
    classNames={getClass(props.direction)}
    unmountOnExit >
    <ContentProvider { ...props } />
  </CSSTransition>

)

export default ContentAnimator;