import * as _ from 'lodash';
import Rating from '../rating/RatingCreator';
import imgFallback from '../../assets/img/product/placeholder.jpg';

function SearchCreator( data, prevList = [] ) {
  const productList = getProductList(data)
  const nextPage = _.get( data._links, 'next.href' )
  this.hasResult = productList.length > 0;
  this.list = [
    ...prevList,
    ...productList,
  ];
  this.next_page = nextPage;
}

export default SearchCreator;

function getProductList(data) {
  const productList = _.get( data._embedded, '["bgd:product"]' )
  let newList = []
  productList.forEach(( product ) => {
    newList.push({
      ...product,
      rating: getRating( product ),
      img: getImage( product )
    })
  })
  return newList;
}

function getImage( product ) {
  const image = _.get( product._links, 'image[1].href', imgFallback )
  // console.log(image)
  return image
}

function getRating(product) {
  const rating = new Rating( product.product_ratings )
  return rating.health_rating
}