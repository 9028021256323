
const initialState = {
  intro_page: 1
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    
    case 'INCREMENT_INTRO':
      return {
        ...state,
        intro_page: state.intro_page + 1,
      }

    
    default :
      return state;
  }
}