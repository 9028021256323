import React from 'react';
import monsterSuccess from '../../../assets/img/monsters/monster-success.gif'

const Success = () => (
  <div>
    Danke für deine Unterstützung
    <img
      className="img-monster"
      src={monsterSuccess}
      alt='bettergood success monster' />
  </div>
)

export default Success;