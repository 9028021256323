import React from 'react';

const RatingMapper = ({
  ratings,
  isActive = 0
}) => {
  const textMap = ratings.map(( text, i) => (
    <span key={i} style={ isActive === i ? {} : {color: '#d4d4d4'} }>
      { text }
    </span>
  ))
  return (
    <div className="configurator__rating">
      { textMap }
    </div>
  )
}

export default RatingMapper