import React, { PureComponent } from 'react';
import ReactGA from 'react-ga';
import OpeningView from './OpeningView';
import * as _ from 'lodash';
import content from './content';

class OpeningContainer extends PureComponent {

  state = {
    i: 0,
    showAppStore: false,
    customHistory: [],
    direction: '',
  }

  onProgress = ( content = {} ) => {
    this.updateIndex( content.link_to )
    this.checkTrigger( content.trigger )
  }

  updateIndex( link ) {
    const { i, customHistory } = this.state;
    const newIndex = link ? _.findIndex( content, { id: link } ) : i + 1 
    if ( i < content.length - 1 ) {
      this.setState({
        i: newIndex,
        customHistory: customHistory.concat( i ),
        direction: 'forth'
      })
      const gaData = content[newIndex].id || newIndex
      this.sendToAnalytics(gaData)
    }
  }

  sendToAnalytics(index) {
    const store = '/announcement/' + index
    ReactGA.pageview(store);
  }

  checkTrigger( trigger ) {
    if ( trigger === 'App Store' ) {
      this.setState({ showAppStore: true })
    }
  }

  onAppStoreClick() {
    ReactGA.event({
      category: 'App Store',
      action: 'visit',
    });
  }

  onBack = () => {
    const { customHistory } = this.state
    const i = customHistory[ customHistory.length - 1 ]
    const newHistory = customHistory.slice(0, customHistory.length -1)
    if ( customHistory.length ) {
      this.setState({
        i,
        customHistory: newHistory,
        direction: 'back',
      })
    }
  }

  render() {
    const { i, showAppStore, customHistory, direction } = this.state;
    return (
      <OpeningView
        showAppStore={ showAppStore }
        onAppStoreClick={ this.onAppStoreClick }
        page={ i }
        direction={ direction }
        onProgress={ this.onProgress }
        onBack={ this.onBack }
        showBackButton={ customHistory.length }  />
    )
  }
}

export default OpeningContainer;
